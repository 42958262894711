<template>
    <div class="container-with-fixed-bottom roadtrip-new with-botom-link">
        <LoadingIcon :isLoading="isLoading" />
        <ProductInformation :roadtrip="roadtrip" :isLoading="isLoading" @save="verifyForm" />
        <MessageActionDialog @yes="confirmSave()" />
    </div>
</template>
<script>
import ProductInformation from "@/components/admin/roadtrip/ProductInformation";
import LoadingIcon from '@/components/common/LoadingIcon';
import { Roadtrip } from '@/models/Roadtrip/Roadtrip';
import MessageActionDialog from "@/components/dialogs/MessageActionDialog";
import RouteMixin from "@/mixins/route.mixin";
import RoadtripValidations from "@/mixins/validations/roadtrip-validations.mixin";
export default {
    name: 'RoadtripNieuw',
    components: 
    {
        LoadingIcon,
        ProductInformation,
        MessageActionDialog
    },
    mixins: [RouteMixin, RoadtripValidations],
    beforeRouteLeave (to, from , next) {
               
        if (this.successfullyAdded)
        {
            next();
        }
        else
        {
            const answer = window.confirm('Weet u zeker dat u deze pagina wilt verlaten?')
            if (answer) {
                next()
            } else {
                next(false)
            }
        }
    },
    data: () => ({
        deleteIcon: require('@/assets/icons/delete.svg'),
        isLoading: false,
        successfullyAdded: false,
        roadtrip: new Roadtrip()
    }),
    methods: 
    {
        verifyForm()
        {
            this.isLoading = true;
            // set planning organization same as planning participant for now.
            this.roadtrip.organizationItinerary = this.roadtrip.participantsItinerary;

            // additional checks for adding new road trip
            this.roadtrip.productPhotos = this.roadtrip.images.filter(x => x.file != null).map(x => x.file);
            let errors = this.checkRoadtripPhotos(this.roadtrip.productPhotos);
            if(errors != "")
            {
                this.$store.commit('dialog/showDialog', errors);
                this.isLoading = false;
                return;
            }
           
            this.showConfirmMessage();
        },
        showConfirmMessage()
        {
            if (this.roadtrip.isPublished)
            {
                this.isLoading = false;
                let message = "U heeft de status op actief gezet. Hiermee wordt een e-mail naar alle subscribers verzonden. Wit u verdergaan?";
                this.$store.commit('dialog/showActionDialog', message);
            }
            else
            {
                this.confirmSave();
            }
        },
        confirmSave()
        {
            this.isLoading = true;
            this.$store.dispatch('roadtripModule/addRoadtrip', this.roadtrip)
            .then(response => {
                this.successfullyAdded = true;
                var emailSentMessage = response.updateSentToSubscriber ? "Er is een e-mail verzonden naar alle subscriebrs van de mailing." : "";
                // redirect to dashboard.
                this.$store.commit('dialog/showDialog', `Roadtrip succesvol toegevoegd. ${emailSentMessage}`);
                this.goToMyDashboard();
            })
            .catch(() => {
                this.$store.commit('dialog/showDialog', 'Er ging iets mis, de roadtrip is niet correct opgeslagen.');
                this.isLoading = false;
            })
        }
    }
}
</script>

<style>


.editable-text .icon
{
    position: absolute;
    width: 15px;
    height: 15px;
    margin: 4px 7px;
}

.upload-image
{
    position: absolute;
    width: 130px;
    height: 130px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.upload-container
{
    max-width: 100vw;
    position: relative;
    background-color: #0071E3;
}

.extra-options 
{
    position: relative;
    cursor: pointer;
}

.extra-options .v-icon
{
    position: absolute;
    right: -12px;
}

.roadtrip-new.with-botom-link
{
    padding-bottom: 60px;
    overflow-y: auto;
}

</style>

