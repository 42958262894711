<template>
    <div class="message-dialog">
        <v-row class="no-gutters">
            <v-col cols="auto">
                <v-dialog persistent color="white"
                    max-width="600"
                    v-model="dialogVisible">
                    <template>
                        <v-card>
                            <v-card-text>
                                <div class="subtitle-2 px-8 pt-10" v-html="dialogMessage"></div>
                            </v-card-text>
                            <v-card-actions class="pb-5">
                                <v-spacer></v-spacer>
                                <v-btn @click.stop="yes">Ja</v-btn>
                                <v-btn @click.stop="no">Nee</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'MessageActionDialog',

    data: () => ({
       
    }),
    computed: {
        ...mapState({
            dialogVisible: state => state.dialog.isActionDialogVisible,
            dialogMessage: state => state.dialog.actionMessage
        })
    },
  
    methods: {
        yes() 
        {
            this.$emit('yes');
            this.$store.commit('dialog/hideActionDialog');
        },
        no()
        {
            this.$store.commit('dialog/hideActionDialog');
        }
    }
  }
</script>
